import { render } from '@testing-library/react';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import CalendarHeatmap from '../../components/graphic/CalendarHeatMap';

const REACT_DJANGO = process.env.REACT_APP_DJANGO

let url = REACT_DJANGO +'/api/danziger/lifts/'


const ListLifts = ()=>{

  const [data,setData] = useState({})
  const [event,setEvent] = useState({})
  const [isLoading, setLoading] = useState(true);

  useEffect(() =>{



  const getLifts = async () =>{

  const response = await fetch(url,{
    method:'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      // 'Authorization':'Bearer '+this.token
    },
    })
    
    const resopnseJson = await response.json();
    console.log("json", resopnseJson);
    setData(resopnseJson);
    setLoading(false);
  }
  getLifts()
  
},[])
  
  

  if (isLoading) {
    return (
      <div>loading

    </div>
    )
  } else {

    return (
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <CalendarHeatmap data= {data.lifttime}/>
              <table className="min-w-full">
              
              <thead className='bg-white border-b'>

                <tr>
                  <th
                    scope="col"
                    className="text-medium font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Lift Times
                  </th>
                  <th
                    scope="col"
                    className="text-medium font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Med.Ht.
                  </th>
                </tr>
                </thead>
                <tbody>
                
                  {data.lifttime.map((item,key) => {console.log(item)
                    return (
                      <tr className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100'>

                      <td
                        scope="col"
                        className="text-lg text-gray-900 font-light px-6 py-2 whitespace-nowrap"
                      >
                        <Link to={`/danzEvent/${item[0]}`}>
                          {item[0]}
                        </Link>
                      </td>
                      <td
                        scope="col"
                        className="text-lg text-gray-900 font-light px-6 py-2 whitespace-nowrap"
                      >

                          { (item[1] != null) ? item[1].toFixed(0):'Maybe Invalid'}

                      </td>
                      </tr>
                    );
                  })}
                  
                </tbody>

                
                
              </table>
            </div>
          </div>
        </div>
      </div>
    );

}
}

export default ListLifts