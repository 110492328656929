import React from 'react'
// import './styles.css'
// Import the images
import railImage from './assets/rail.png';
import girderImage from './assets/girder.png';
import windImage from './assets/wind.png';
import seismoImage from './assets/seismo.png';

function Home() {
  // Sample data for your projects. You should replace this with your actual data or fetch from an API.
  const projects = [
    {
      image: railImage,
      title: "Data Viusalizations",
      description: "WJE Analytics focuses on developing interactive data visualizations that are meaningful. We utilize images, 3D models, and processed data to fuse together meaningful information. Not just a strain from a strain gage, but post-processing sets of gages into meaningful stresses and forces on the components being evaluated."
    },
    {
      image: girderImage,
      title: "Real Time Evaluation",
      description: "WJE Analytics combined with Monitoring solutions, as well as accessing accessible instrumentation data clouds we can provide real-time web analystics for various monitoring solutions for real-time meaningful evaluation."
    },
    {
      image: windImage,
      title: "Big Data",
      description: "WJE Analytics & Monitoring handles numerous project from across the US over the past 10 years. We have solutions that can handle millions of data points."
    },
    {
      image: seismoImage,
      title: "Custom Solutions",
      description: "WJE Analytics & Monitoring build custom solutions to the problems. Utilizing understanding of the structure first that incorporate data derive the behavior."
    },
    // ... Add more projects
  ];

  return (
    <>
      <div className='hero2'>
        {projects.map((project, index) => (
          <div key={index} className='project-container'>
            <img className='project-image' src={project.image} alt={project.title} />
            <div className='project-info'>
              <h2 className='text-white text-2xl'>{project.title}</h2>
              <p className='text-white text-md'>{project.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Home;

