import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const CalendarHeatmap = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 40, left: 50 };
    const width = 1000 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;

    const svg = d3
      .select(ref.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const day = d3.timeDay;
    const week = d3.timeWeek;
    const month = d3.timeMonth;
    const format = d3.timeFormat('%Y-%m-%d');
    const formatMonth = d3.timeFormat('%b');
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const startDate = new Date(2020, 1, 1);
    const endDate = new Date();

    const liftCount = new Map();

    data.forEach((elem) => {
      const date = format(new Date(elem[0]));
      liftCount.set(date, (liftCount.get(date) || 0) + 1);
    });

    const colorScale = d3
      .scaleSequential()
      .domain([0, d3.max(Array.from(liftCount.values()))])
      .interpolator(d3.interpolateViridis);

    const cellSize = 17;
    const cellMargin = 2;

    const year = svg
      .selectAll('.year')
      .data(d3.range(startDate.getFullYear(), endDate.getFullYear() + 1))
      .enter()
      .append('g')
      .attr('class', 'year')
      .attr('transform', (d, i) => `translate(0,${i * (cellSize + cellMargin) * 7})`);
    // Year labels
    year
      .append('text')
      .attr('class', 'year-label')
      .attr('x', -margin.left +0)
      .attr('y', cellSize * 3.5)
      .attr('text-anchor', 'start')
      .attr('font-size', '14px')
      .attr('font-weight', 'bold')
      .text((d) => d);

    // Day of week labels
    // Day of week labels
    year
      .append('g')
      .attr('class', 'weekday-labels')
      .selectAll('.weekday')
      .data((d) => days.map((day, i) => ({ day, i })))
      .enter()
      .append('text')
      .attr('class', 'weekday')
      .attr('x', 0)
      .attr('y', (d) => d.i * (cellSize + cellMargin))
      .attr('dy', '0.85em')
      .attr('text-anchor', 'end')
      .attr('font-size', '12px')
      .text((d) => d.day);

    // Calendar cells
    year
    .selectAll('.day-cell')
    .data((d) => d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
    .enter()
    .append('rect')
    .attr('class', 'day-cell')
    .attr('width', cellSize)
    .attr('height', cellSize)
    .attr('x', (d) => week.count(new Date(d.getFullYear(), 0, 1), d) * (cellSize + cellMargin))
    .attr('y', (d) => d.getDay() * (cellSize + cellMargin))
    .attr('fill', '#e0e0e0')
    .datum(format);

    year
      .selectAll('.day-cell')
      .data((d) =>
      d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)).map((date) => ({
        date: format(date),
        value: liftCount.get(format(date)),
      })),
    )
    .attr('fill', (d) => (d.value === undefined ? '#e0e0e0' : colorScale(d.value)));

  // Month labels and lines
  const monthLabels = year
  .selectAll('.month-label')
  .data((d) => d3.timeMonths(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
  .enter()
  .append('g')
  .attr('class', 'month-label');

monthLabels
  .append('text')
  .attr('x', (d) => week.count(new Date(d.getFullYear(), 0, 1), d) * (cellSize + cellMargin))
  .attr('y', -5)
  .attr('font-size', '12px')
  .text((d) => formatMonth(d));

  monthLabels
    .append('line')
    .attr('x1', (d) => week.count(startDate, d) * (cellSize + cellMargin))
    .attr('x2', (d) => week.count(startDate, d) * (cellSize + cellMargin))
    .attr('y1', 0)
    .attr('y2', 28 * (cellSize + cellMargin))
    .attr('stroke', '#000')
    .attr('stroke-width', '1px');

  // Tooltip
  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'heatmap-tooltip')
    .style('opacity', 0)
    .style('position', 'absolute')
    .style('background-color', 'white')
    .style('border', '1px solid #000')
    .style('border-radius', '4px')
    .style('padding', '4px');

  year
    .selectAll('.day-cell')
    .on('mouseover', (event, d) => {
      tooltip.style('opacity', 1);
      tooltip
        .html(`Date: ${d.date}<br>Lifts: ${d.value === undefined ? 'N/A' : d.value}`)
        .style('left', event.pageX + 10 + 'px')
        .style('top', event.pageY - 30 + 'px');
    })
    .on('mousemove', (event) => {
      tooltip
        .style('left', event.pageX + 10 + 'px')
        .style('top', event.pageY - 30 + 'px');
    })
    .on('mouseout', () => {
      tooltip.style('opacity', 0);
    });

}, [data]);

return <svg ref={ref}></svg>;
};

export default CalendarHeatmap;

