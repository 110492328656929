import React from "react";

const WaveformList = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full">
              <thead className="bg-white border-b">
                <tr>
                  <th scope="col" className="px-6 py-4 text-left">
                    Timestamp
                  </th>
                  <th scope="col" className="px-6 py-4 text-left">
                    Seismo ID
                  </th>
                  <th scope="col" className="px-6 py-4 text-left">
                    Tran PK PPV
                  </th>
                  <th scope="col" className="px-6 py-4 text-left">
                    Long PK PPV
                  </th>
                  <th scope="col" className="px-6 py-4 text-left">
                    Vert PK PPV
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    <td className="px-6 py-2 whitespace-nowrap">{item.timestamp}</td>
                    <td className="px-6 py-2 whitespace-nowrap">{item.seismo_id}</td>
                    <td className="px-6 py-2 whitespace-nowrap">{item.tran_pk_ppv}</td>
                    <td className="px-6 py-2 whitespace-nowrap">{item.long_pk_ppv}</td>
                    <td className="px-6 py-2 whitespace-nowrap">{item.vert_pk_ppv}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaveformList;
