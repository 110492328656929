import React from 'react'
import * as d3 from 'd3'
import danzigerInstrumention from './instrumentation.jpg'

function Graphic(data) {
  console.log('graphic inner')
console.log(data.data.status_601.fields)

const numberFormat = ((temp) => {
  console.log('inside tempFormat')
  if (temp) {
    return temp.toFixed(2)
  } else { return "NA"}
})
  
  const svgRef = React.useRef(null)
  const dimensions = {
    width: 1000,
    height: 800,
    margin: { top: 0, right: 30, bottom: 30, left: 60 },
  }



  const { width, height, margin } = dimensions
  const svgWidth = width + margin.left + margin.right
  const svgHeight = height + margin.top + margin.bottom

  React.useEffect(() => {
    const svgEl = d3.select(svgRef.current)
    const svg = svgEl
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    svg
      .append('image')
      .attr('xlink:href', danzigerInstrumention)
      //   .attr('xlink:href', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0u8Q5eULmw4XN3KMnkgjcCjDj9fUVVvb9QA&usqp=CAU')
      .attr('width', 1000)
      .attr('height', 800)

    svg
      .append('circle')
      .attr('id', 'circleBasicTooltip')
      .attr('cx', 572)
      .attr('cy', 526)
      .attr('r', 5)
      .attr('fill', 'red')
      .append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .text("I'm a circle!")
      svg
      .append('circle')
      .attr('id', 'circleBasicTooltip')
      .attr('cx', 83)
      .attr('cy', 526)
      .attr('r', 5)
      .attr('fill', 'red')
      .append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .text("I'm a circle!")
      svg
      .append('circle')
      .attr('id', 'circleBasicTooltip')
      .attr('cx', 956)
      .attr('cy', 310)
      .attr('r', 5)
      .attr('fill', 'red')
      .append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .text("I'm a circle!")

    svg
      .append('text')
      .attr('x', 549)
      .attr('y', 575)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text(`VSW:${numberFormat(data.data.status_601.fields.vsw_med)}`)
    svg
      .append('text')
      .attr('x', 603)
      .attr('y', 528)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text(`LSW:${numberFormat(data.data.status_601.fields.lsw_med)}`)
    svg
      .append('text')
      .attr('x', 538)
      .attr('y', 551)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text(`TSW:${numberFormat(data.data.status_601.fields.tsw_med)}`)

      svg
      .append('text')
      .attr('x', 81)
      .attr('y', 575)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text(`VNW:${numberFormat(data.data.status_501.fields.vnw_med)}`)
    svg
      .append('text')
      .attr('x', 0)
      .attr('y', 528)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text('LNW:')
    svg
      .append('text')
      .attr('x', 81)
      .attr('y', 551)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text(`TNW:${numberFormat(data.data.status_501.fields.tnw_med)}`)

      svg
      .append('text')
      .attr('x', 301)
      .attr('y', 558)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text(`West Jt:${numberFormat(data.data.status_501.fields.w_joint_laser_avg)}`)

      
      svg
      .append('text')
      .attr('x', 980)
      .attr('y', 336)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text('VSE:')
    svg
      .append('text')
      .attr('x', 980)
      .attr('y', 315)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text('LSE:')
    svg
      .append('text')
      .attr('x', 980)
      .attr('y', 290)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text(`TSW:${numberFormat(data.data.status_601.fields.tsw_med)}`)

      svg
      .append('text')
      .attr('x', 380)
      .attr('y', 336)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text('VNE:')
    svg
      .append('text')
      .attr('x', 380)
      .attr('y', 315)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text('LNE:')
    svg
      .append('text')
      .attr('x', 380)
      .attr('y', 290)
      .attr('dy', '.35em')
      .attr('fill', 'red')
      .text('TNE:')

    svg.on('mousemove', function (event) {
      // console.log("x - using invert", d3.pointer(this)[0]);
      // console.log("y- using invert", d3.pointer(this)[1]);
      console.log(d3.pointer(event))
    })
    //   var textout = 'hello'
  }, [])
  return <svg ref={svgRef} width={svgWidth} height={svgHeight} />
}
export default Graphic
