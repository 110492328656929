import React from 'react'
import Graph_danziger_TS from '../../components/plots/PlotDanziger'
import { useEffect, useState } from 'react'
import Graphic from '../../components/graphic/Graphic'
import Mapbox from '../Mapbox_old'

function DanzigerCameraScreen() {
  // const { eventtime } = useParams()
  const [data, setData] = useState({})
  const [event, setEvent] = useState({})
  const [isLoading, setLoading] = useState(true)
  const today = new Date()
  const start = new Date(today)

  let days = 1

  start.setDate(start.getDate() - days)
  today.setDate(today.getDate() + 1)
  // today.toISOString()
  // yesterday.toISOString()
  // console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)
  const start_time = start.toISOString().split('T')[0] + ' 00:00:00'

  const end_time = today.toISOString().split('T')[0] + ' 00:00:00'

  console.log(start_time)

  const REACT_DJANGO = process.env.REACT_APP_DJANGO
  let url = REACT_DJANGO + '/api/danziger/status/'

  useEffect(() => {
    const getStatus = async () => {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'Authorization':'Bearer '+this.token
        },
      })

      const resopnseJson = await response.json()
      console.log('json', resopnseJson.status_501)
      setData(resopnseJson)
      setLoading(false)
    }
    getStatus()
  }, [])

  if (isLoading) {
    return <div>loading</div>
  } else {
  return (
    <div>
      <div class='flex flex-wrap flex-row'>
        <div class='flex-1 mt-5'>
          <iframe
            src='https://g1.ipcamlive.com/player/player.php?alias=6178432aed33f'
            width='500px'
            height='300px'
            frameborder='0'
            allowfullscreen
          ></iframe>{' '}
        </div>
        <div class='flex-1 mt-5'>
          <h2 className='bg-slate-300 text-2xl'>Shoe Movement</h2>

          <Graph_danziger_TS
            gagelist={{
              primary: [
                { gage: 'nw_shoe_avg', name: 'nw_shoe', table: 'Dan501' },
                {
                  gage: 'sw_shoe_avg',
                  name: 'sw_shoe',
                  table: 'Dan501',
                  tare: 0,
                },
              ],
              y_range: [0, 70],
              title: 'West Shoes',
            }}
            dateRange={{ dateRange: [start_time, end_time] }}
            config={{
              y_axes_range: [-0.1, 2.0],
              y_axis_label: 'Displacement (in.)',
            }}
          />
          <Graph_danziger_TS
            gagelist={{
              primary: [
                { gage: 'ne_shoe_avg', name: 'ne_shoe', table: 'Dan502' },
                {
                  gage: 'se_shoe_avg',
                  name: 'se_shoe',
                  table: 'Dan502',
                  tare: 0,
                },
              ],
              y_range: [0, 70],
              title: 'East Shoes',
            }}
            dateRange={{ dateRange: [start_time, end_time] }}
            config={{
              y_axes_range: [-0.1, 2],
              y_axis_label: 'Displacement (in.)',
            }}
          />
        </div>
      </div>
      <h2 className='bg-slate-300 text-2xl'>
        Instrumentation with Current Readings
      </h2>
      <h6 className='bg-slate-300 text-xs'>
        Work in progress to show current readings
      </h6>
      <Graphic data={data}/>

      {/* <Mapbox/> */}
    </div>
  )}
}

export default DanzigerCameraScreen
