import { Fragment, useState, useContext } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  solid,
  regular,
  brands,
  duotone,
} from '@fortawesome/fontawesome-svg-core/import.macro'

function MenuDanziger({show}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  return (
    <Menu as='div' className='flex-shrink-0 relative ml-4'>
    <div>
      <Menu.Button className='bg-white-800 flex  text-gray-300 hover:bg-gray-700 hover:text-white  rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white px-3 py-2 rounded-md text-sm font-medium'>
        <span className='sr-only'>Open user menu</span>
        Danziger &nbsp;<div className='items-center'><FontAwesomeIcon icon={solid('square-chevron-down')} /></div>
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <Menu.Items className='origin-top-left absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
        <Menu.Item>
          {({ active }) => (
            <a
              href='/danziger/7'
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-gray-700'
              )}
            >
              Past 7-days
            </a>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <a
              href='/danziger/28'
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-gray-700'
              )}
            >
              Past 28-days
            </a>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <a
              href='/danzlifts'
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-gray-700'
              )}
            >
              Lift list
            </a>
          )}
        </Menu.Item>

        <Menu.Item>
          {({ active }) => (
            <a
              href='/danzCamera'
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-bold text-gray-700'
              )}
            >
              Live View
            </a>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <a
              href='/danzigermap'
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-gray-700'
              )}
            >
              Geolocation
            </a>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
  )
}

export default MenuDanziger
