import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
const REACT_DJANGO = process.env.REACT_APP_DJANGO

function PlotWaveform(props) {
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({});
  const [longData, setLongData] = useState([]);
  const [tranData, setTranData] = useState([]);
  const [vertData, setVertData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      //let url = REACT_DJANGO +props.url//'/api/seismo/getwavedetail/'
      let url = REACT_DJANGO +'/api/seismo/getwavedetail/'

      //Get authorization token
      // var userData = JSON.parse(localStorage.getItem('userInfo'))
      // const token = userData.token;

      // const response = await fetch(url,{
      //   method: 'POSGT',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Accept': 'application/json',
      //     'Authorization':'Bearer ' + token
      //   },
      //   body: JSON.stringify({
      //     'gagelist': props.gagelist,
      //     'dateRange': props.dateRange,
      //     'config': props.config
      //   })
      // });

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'Authorization':'Bearer '+this.token
        },
      })
      

      const data = await response.json();
      console.log(data)      
      console.log(data['0']['0'])
      // setData(data.data);
      // setLayout(data.layout);
      const processData = (dataString) => {
        return dataString
          .trim()
          .split(',')
          .map((value) => parseFloat(value));
      };

      setLongData(processData(data['0']['0'].long));
      setTranData(processData(data['0']['0'].tran));
      setVertData(processData(data['0']['0'].vert));
    }
    fetchData();
  }, []);  //[props.gagelist, props.dateRange, props.config]

  const createXValues = (data) => data.map((_, index) => (index + 1) / 1024);

  return (
    <Plot
      data={[
        {
          x: createXValues(longData),
          y: longData,
          type: 'scatter',
          mode: 'lines',
          marker: { color: 'red' },
          name: 'Long',
        },
        {
          x: createXValues(tranData),
          y: tranData,
          type: 'scatter',
          mode: 'lines',
          marker: { color: 'green' },
          name: 'Tran',
        },
        {
          x: createXValues(vertData),
          y: vertData,
          type: 'scatter',
          mode: 'lines',
          marker: { color: 'blue' },
          name: 'Vert',
        },
      ]}
      layout={{ width: 800, height: 400, title: 'longitudinal' ,yaxis: {range: [-0.1, .1]}}}
      config={{displayModeBar: false}}
    />
  );
};

export default PlotWaveform;
