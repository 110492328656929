import React, { createContext, useReducer} from 'react'
import userReducer from './UserReducer'

const UserContext = createContext()

const DJANGO_URL = process.env.REACT_APP_DJANGO
// const GITHUB_TOKEN = process.env.REACT_APP_GITHUB_TOKEN

export const UserProvider = ({children}) => {
    const initialState = {
        userInfo: [],
        username:'',
        password:'',
        loading: false
    }

    const [state, dispatch] = useReducer(userReducer, initialState)

    const loginUser = async (username,password) => {
        setLoading()
        
        console.log('loginurl: ',`${DJANGO_URL}/api/users/login/`)
        const response = await fetch(`${DJANGO_URL}/api/users/login/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-type':'application/json'
            },
            body:  JSON.stringify({
                'username':username, 'password':password}),
            
        })
        
        if(response.status === 200) {
            window.location = '/status'
        }

        if(response.status === 404) {
            window.location = '/notfound'
        } else {
            const data = await response.json()
    
            // setUsers(data)
            // setLoading(false)
            dispatch({
                type: 'LOGIN',
                payload: data,
            })
            if(data.access) {
                localStorage.setItem('userInfo', JSON.stringify(data))
            } else {
                console.log('no access') 
            }
        }

    }

    const setLoading = () => dispatch({type:'SET-LOADING'})

    //clearUsers from state
    const clearUsers = () => dispatch({type:'CLEAR'})

    return <UserContext.Provider value={ {
        userInfo: state.userInfo,
        user:state.user,
        password:state.password,
        loading: state.loading,
        loginUser,

        }}>
        {children}
    </UserContext.Provider>
}

export default UserContext
