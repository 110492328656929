import { render } from '@testing-library/react';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

const REACT_DJANGO = process.env.REACT_APP_DJANGO

let url = REACT_DJANGO +'/api/danziger/get_bearings_event/'


const BearingData = ()=>{
  const { eventtime } = useParams()
  const [data,setData] = useState({})
  const [event,setEvent] = useState({})
  const [isLoading, setLoading] = useState(true);

  const eventTS = new Date(eventtime)
  const start = new Date(eventTS.setMinutes(eventTS.getMinutes() - 5 - 4 * 60))
  const start_time = eventTS.toISOString().slice(0, -1)
  const end_time = new Date(eventTS.setMinutes(eventTS.getMinutes() + 10)).toISOString().slice(0, -1)
  console.log(JSON.stringify({'dateRange':[start_time,end_time]}))
  useEffect(() =>{

  const getBearingData = async () =>{

  const response = await fetch(url,{
    method:'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      // 'Authorization':'Bearer '+this.token
    },
    body: JSON.stringify({'dateRange':[start_time,end_time]})
})

    const resopnseJson = await response.json();
    console.log("json", JSON.parse(resopnseJson));

    setData(JSON.parse(resopnseJson));
    setLoading(false);
  }
  getBearingData()
  
},[])
  
  

  if (isLoading) {
    return (
      <div>loading

    </div>
    )
  } else {

    return (
      <div className="flex flex-col">
 
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <h2>SW_OB</h2>

              <table>

                  <thead className='bg-white border-b'>
                    <tr>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_0_BandA</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_1_BandA</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_2_BandA</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_3_BandA</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
                    </tr>
                    <tr>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_0_banda[0]}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_1_banda[0]}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_2_banda[0]}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_3_banda[0]}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_0_banda[0],data.sensor_1_banda[0],data.sensor_2_banda[0],data.sensor_3_banda[0])}</td>
                    </tr>
                    <tr>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_0_bandb[0]}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_1_bandb[0]}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_2_bandb[0]}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_3_bandb[0]}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_0_bandb[0],data.sensor_1_bandb[0],data.sensor_2_bandb[0],data.sensor_3_bandb[0])}</td>
                    </tr>
                  </thead>
              </table>
              {/* <table className="min-w-full">
              
              <thead className='bg-white border-b'>

                <tr>
                  <th
                    scope="col"
                    className="text-medium font-medium text-gray-900 px-4 py-4 text-left"
                  >
                    Lift Times
                  </th>
                </tr>
                </thead>
                <tbody>
                
                  {data?.comment.map((item) => {
                    return (
                      <tr className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100'>

                      <td
                        scope="col"
                        className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                      >

                          {item}

                      </td>
                      </tr>
                    );
                  })}
                  
                </tbody>

                 
              </table> */}
            </div>
          </div>
        </div>
      </div>
    );

}
}

export default BearingData