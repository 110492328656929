import React, { useEffect, useState } from "react"
import GraphSeismo from "../../components/plots/PlotSeismo"
import GraphSeismoHz from "../../components/plots/PlotSeismoHz"
import GraphicH from "../../components/graphic/hyatt.jsx"
import Plot from "react-plotly.js"
import { RadioGroup } from '@headlessui/react';
import moment from "moment"

const REACT_DJANGO = process.env.REACT_APP_DJANGO

const gages = ["histo_um6380", "histo_um10309_slm", "histo_um12319_slm"]
const gagePrettyNames = {
  histo_um6380: "Hyatt V1- UM6380",
  histo_um12319_slm: "Hyatt V2- UM12319",
  histo_um10309_slm: "Hyatt V3- UM10309",
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


const plotOptions = {
  "Time vs PPV (in/s)": {
    data: [
      {
        x: (datum) => new Date(datum.timestamp),
        y: (datum) => datum.tran_ppv,
        name: "tran_ppv",
      },
      {
        x: (datum) => new Date(datum.timestamp),
        y: (datum) => datum.long_ppv,
        name: "long_ppv",
      },
      {
        x: (datum) => new Date(datum.timestamp),
        y: (datum) => datum.vert_ppv,
        name: "vert_ppv",
      },
    ],
    layout: {
      height: 300,
      xaxis: { title: 'Time' },
      yaxis: { title: 'PPV (in/s)', range: [0, 0.5] }
    },
    inStock: true,
    name: "Time vs PPV (in/s)"
  },
  "Freq (Hz) vs PPV (in/s)": {
    data: [
      {
        x: (datum) => datum.tran_hz,
        y: (datum) => datum.tran_ppv,
        name: "tran_ppv",
      },
      {
        x: (datum) => datum.tran_hz,
        y: (datum) => datum.long_ppv,
        name: "long_ppv",
      },
      {
        x: (datum) => datum.tran_hz,
        y: (datum) => datum.vert_ppv,
        name: "vert_ppv",
      },
    ],
    layout: {
      height: 300,
      xaxis: { type: "log", title: 'Freq. (Hz)' },
      yaxis: { type: "log",title: 'PPV (in/s)', range: [-3, 0] },
    },
    inStock: true,
    name: "Freq (Hz) vs PPV (in/s)"
  },
  "Time vs Leq (dBA)": {
    data: [
      {
        x: (datum) => new Date(datum.timestamp),
        y: (datum) => datum.leq,
        name: "leq",
      },
    ],
    layout: {
      height: 300,
      xaxis: { title: 'Time' },
      yaxis: { title: 'Leq (dBA)', range: [40, 100] }  // Adjusted the y-axis range heres */
    },
    inStock: true,
    name: 'Time vs Leq (dBA)'
  },
}
// const plotOptionsList = [
//   { name: 'Time vs PPV (in/s)', inStock: true },
//   { name: 'Freq (Hz) vs PPV (in/s)', inStock: true },
//   { name: 'Time vs Leq (dBA)', inStock: true },
// ];

export default function PlotScreenSeismoHyatt() {
  const [data, setData] = useState({})
  const [tempDateRange, setTempDateRange] = useState({
    // Temporary date range for the datepicker
    from_date: moment().subtract(3, "days").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
  })
  
  const [dateRange, setDateRange] = useState({ ...tempDateRange }) // The date range that's used for fetching
  const [selectedOption, setSelectedOption] = useState("Time vs PPV (in/s)")
  //const [selectedOption, setSelectedOption] = useState(plotOptions[0]);

  //const dataForPlot = plotOptions[selectedOption].data;
  const handleDateUpdate = () => {
    // Ensure the date range is not more than 10 days
    if (
      moment(tempDateRange.to_date).diff(
        moment(tempDateRange.from_date),
        "days"
      ) <= 10
    ) {
      setDateRange(tempDateRange)
    } else {
      alert("Please select a date range not exceeding 10 days.")
    }
  }

  useEffect(() => {
    const fetchDataForGage = async (currentGage) => {
      try {
        const payload = {
          gage: currentGage,
          dateRange: [dateRange.from_date, dateRange.to_date],
        }

        const response = await fetch(
          REACT_DJANGO +"/api/seismo/gagedata/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        )
        const jsonData = await response.json()
        setData((prevData) => ({
          ...prevData,
          [currentGage]: jsonData,
        }))
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    gages.forEach((gage) => fetchDataForGage(gage))
  }, [dateRange])
  useEffect(() => {
    console.log(selectedOption);
  }, [selectedOption]);
  
  // if (plotOptions[selectedOption] && plotOptions[selectedOption].data) {
  //   const dataForPlot = plotOptions[selectedOption].data;
  // } else {
  //   console.error("Invalid selected option or data missing:", selectedOption);
  // }

  return (
    <div>
      {/* Graphic Header */}
      <GraphicH />

      {/* Datepicker Input */}
      <label>From:</label>
      <input
        type="date"
        value={tempDateRange.from_date}
        onChange={(e) =>
          setTempDateRange((prev) => ({ ...prev, from_date: e.target.value }))
        }
      />
      <label>To:</label>
      <input
        type="date"
        value={tempDateRange.to_date}
        onChange={(e) =>
          setTempDateRange((prev) => ({ ...prev, to_date: e.target.value }))
        }
      />
      <button onClick={handleDateUpdate}>Update Date Range</button>

      {/* Radio Button Input */}
      {/* {Object.keys(plotOptions).map((option) => (
        <div key={option}>
          <input
            type="radio"
            id={option}
            name="plotOption"
            value={option}
            checked={selectedOption === option}
            onChange={() => setSelectedOption(option)}
          />
          <label htmlFor={option}>{option}</label>
        </div>
      ))} */}

<RadioGroup value={selectedOption} onChange={(optionObj) => {
    console.log(optionObj);
    setSelectedOption(optionObj);
}} className="mt-2">
  <RadioGroup.Label className="sr-only">Choose a plotting option</RadioGroup.Label>
  <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
    {Object.values(plotOptions).map((optionObj) => (
      <RadioGroup.Option
        key={optionObj.name}
        value={optionObj.name} // note the .name here

        className={({ active, checked }) =>
          classNames(
            optionObj.inStock ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25',
            active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
            checked
              ? 'bg-indigo-600 text-white hover:bg-indigo-500'
              : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
            'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'
          )
        }
        disabled={!optionObj.inStock}
      >
        <RadioGroup.Label as="span">{optionObj.name}</RadioGroup.Label>
      </RadioGroup.Option>
    ))}
  </div>
</RadioGroup>

      {/* Plots */}
      {gages.map((gage) => {
        const gageData = data[gage] || []
        const currentOption = plotOptions[selectedOption]
        
        console.log(selectedOption)

        const commonYAxis = {
          title: "PPV (in/s)",
          range: [0, 0.5],
        }

        const xAxisTitle =
          currentOption?.layout?.xaxis?.type === "log" ? "Freq. (Hz)" : "Time"

        return (
          <div key={gage}>
            <h2 className="text-2xl">{gagePrettyNames[gage]}</h2>
            <Plot
              data={currentOption.data.map((dataset) => ({
                x: gageData.map((datum) => dataset.x(datum)),
                y: gageData.map((datum) => dataset.y(datum)),
                type: "scatter",
                mode: "markers",
                name: dataset.name,
              }))}
              layout={{
                ...currentOption.layout,
                xaxis: { ...currentOption.layout.xaxis, title: xAxisTitle },
                yaxis: { ...currentOption.layout.yaxis},
              }}
            />
          </div>
        )
      })}
    </div>
  )
}
