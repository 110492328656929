import React from 'react'
import GraphSeismo from '../../components/plots/PlotSeismo'
import GraphSeismoHz from '../../components/plots/PlotSeismoHz'
import GraphicW from '../../components/graphic/westv.jsx'
// import Spinner from '../components/layout/Spinner'

function PlotScreenSeismoWestview() {

const today = new Date()
const start = new Date(today)

start.setDate(start.getDate() - 7)
today.setDate(today.getDate() + 1)
// today.toISOString()
// yesterday.toISOString()
// console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)
const start_time = start.toISOString().split('T')[0] + ' 00:00:00'
const end_time = today.toISOString().split('T')[0] + ' 00:00:00'

// const start_time = '2022-02-21 00:00:00'
// const end_time = '2022-02-26 00:00:00'

  return (
    <div>
      <GraphicW />
      <h1 className="bg-slate-300 text-2xl">Westview V1</h1>
      <h3>V1 - UM12302</h3>
     
      <GraphSeismo gagelist= {{
        "primary" : [ 
          {"gage":"tran_ppv","name":"tran_ppv","table":"histo_um12302"},
        {"gage":"vert_ppv","name":"vert_ppv","table":"histo_um12302"},
        {"gage":"long_ppv","name":"long_ppv","table":"histo_um12302"}] ,
      "y_range": [0, 70],"title":"Time vs PPV"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} config={{"y_axes_range":[0.0,1.0],"y_axes_label":"PPV (in/s)"}}/>
      
      <GraphSeismoHz gagelist= {{
        "primary" : [ 
          {"gage":"tran_ppv","freq":"tran_hz","name":"tran_hz","table":"histo_um12302"},
        {"gage":"vert_ppv","freq":"vert_hz","name":"vert_ppv","table":"histo_um12302"},
        {"gage":"long_ppv","freq":"long_hz","name":"long_ppv","table":"histo_um12302"}] ,
"y_range": [0, 70],"title":"Freq. vs PPV"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />

      <h1 className="bg-slate-300 text-2xl">Westview V2</h1>
      <h3>V2 - UM10307</h3>
     
      <GraphSeismo gagelist= {{
        "primary" : [ 
          {"gage":"tran_ppv","name":"tran_ppv","table":"histo_um10307"},
        {"gage":"vert_ppv","name":"vert_ppv","table":"histo_um10307"},
        {"gage":"long_ppv","name":"long_ppv","table":"histo_um10307"}] ,
      "y_range": [0, 70],"title":"Time vs PPV"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} config={{"y_axes_range":[0.0,1.0],"y_axes_label":"PPV (in/s)"}}/>
      
      <GraphSeismoHz gagelist= {{
        "primary" : [ 
          {"gage":"tran_ppv","freq":"tran_hz","name":"tran_hz","table":"histo_um10307"},
        {"gage":"vert_ppv","freq":"vert_hz","name":"vert_ppv","table":"histo_um10307"},
        {"gage":"long_ppv","freq":"long_hz","name":"long_ppv","table":"histo_um10307"}] ,
"y_range": [0, 70],"title":"Freq. vs PPV"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />

      <h1 className="bg-slate-300 text-2xl">Westview V3</h1>
      <h3>V3 - UM16265</h3>
     
      <GraphSeismo gagelist= {{
        "primary" : [ 
          {"gage":"tran_ppv","name":"tran_ppv","table":"histo_um16265"},
        {"gage":"vert_ppv","name":"vert_ppv","table":"histo_um16265"},
        {"gage":"long_ppv","name":"long_ppv","table":"histo_um16265"}] ,
      "y_range": [0, 70],"title":"Time vs PPV"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} config={{"y_axes_range":[0.0,1.0],"y_axes_label":"PPV (in/s)"}}/>

<GraphSeismoHz gagelist= {{
        "primary" : [ 
          {"gage":"tran_ppv","freq":"tran_hz","name":"tran_hz","table":"histo_um16265"},
        {"gage":"vert_ppv","freq":"vert_hz","name":"vert_ppv","table":"histo_um16265"},
        {"gage":"long_ppv","freq":"long_hz","name":"long_ppv","table":"histo_um16265"}] ,
"y_range": [0, 70],"title":"Freq. vs PPV"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />
   
   
    </div>
  )


}

export default PlotScreenSeismoWestview
