import { render } from '@testing-library/react'
import React from 'react'
import Page from '../components/layout/Weather'
import { getServerData } from '../components/layout/Weather'

function Weather() {
  const city =  getServerData().then(res =>   res.props['city']);
//   const { city, date, time, forecast, error } = serverData;
// const serverData = async () => {
//     const data = await getServerData()
//     return data.props }

console.log(`city of origin ${city}`);

// console.log(data)

  return (
   
    <Page serverData={  city } />
  )

}


export default Weather