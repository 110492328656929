import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Graph_danziger_TS from '../../components/plots/PlotDanziger'
import GraphDanzigerSkew from '../../components/plots/PlotDanzigerSkew'
import BearingDataSimple from './BearingsDanzigerSnipSimple'
import BearingDataSimpleEast from './BearingsDanzigerSnipSimpleEast'
import BearingData from './BearingsDanzigerSnip'
import BearingDataEast from './BearingsDanzigerSnipEast'
import dayjs from 'dayjs'



// import Spinner from '../components/layout/Spinner'

function PlotScreenDanzEvent() {
  const { eventtime } = useParams()
  const [data, setData] = useState({})

  const [isLoading, setLoading] = useState(true)

  // const today = new Date()
  // const start = new Date(today) 
  const start_time = dayjs(eventtime).format('YYYY-MM-DDTHH:mm:ss').toString()
  const end_time = dayjs(eventtime).add(10,'minute').format('YYYY-MM-DDTHH:mm:ss').toString()
// console.log('dayjs')
//   console.log(start_time)
//   console.log(end_time)

  // const eventTS = new Date(eventtime)
  // const intermediate = new Date(eventTS)
  // const start = new Date(intermediate.setMinutes(eventTS.getMinutes() - 4 * 60*0))
  // console.log('eventtime')
  // console.log(eventtime)
  // console.log('javascript')
  // console.log(eventTS)
  // // console.log(start)
  // const start_time = eventTS.toISOString().slice(0, -1)
  // console.log('starttime')
  // console.log(start_time)
  // const end_time = new Date(eventTS.setMinutes(eventTS.getMinutes() + 10))
  //   .toISOString()
  //   .slice(0, -1)
  //   console.log('endtime')
  //   console.log(end_time)
  // console.log('enter plot')


  return (
    <div>
      <h2 className='bg-slate-300 text-2xl'>Vertical</h2>
      <h6 className="bg-slate-300 text-xs">Displayed interval 1 sec.</h6>

      <Graph_danziger_TS
        gagelist={{
          primary: [
            {
              gage: 'vnw',
              name: 'VNW',
              table: 'Dan501_event',
              scalar: 0.08333,
            },
            {
              gage: 'vsw',
              name: 'VSW',
              table: 'Dan601_event',
              scalar: 0.08333,
            },
          ],
          y_range: [0, 70],
          title: 'West Vertical',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{ y_axes_range: [-1.0, 70.0], y_axis_label: 'Lift Ht. (ft.)' }}
      />

      <Graph_danziger_TS
        gagelist={{
          primary: [
            {
              gage: 'vne',
              name: 'VNE',
              table: 'Dan502_event',
              scalar: 0.08333,
            },
            {
              gage: 'vse',
              name: 'VSE',
              table: 'Dan602_event',
              scalar: 0.08333,
            },
          ],
          y_range: [0, 70],
          title: 'East Vertical',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{ y_axes_range: [-1.0, 70.0], y_axis_label: 'Lift Ht. (ft.)' }}
      />

      <h2 className='bg-slate-300 text-2xl'>Skew</h2>
      <h6 className="bg-slate-300 text-xs">Note lasers have time differentials that lead to error while the bridge is moving.</h6>
      <GraphDanzigerSkew
        gagelist={{
          set_one: [
            {
              gage_one: 'vne',
              gage_two: 'vse',
              table_one: 'Dan502_event',
              table_two: 'Dan602_event',
              name: 'East Skew',
            },
            {
              gage_one: 'vnw',
              gage_two: 'vsw',
              table_one: 'Dan501_event',
              table_two: 'Dan601_event',
              name: 'West Skew',
            },
          ],
          y_range: [0, 70],
          title: 'Horizontal Skew',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{ y_axes_range: [0.0, 6], y_axes_label: 'Skew (in)',longskew:0 }}
      />

      <GraphDanzigerSkew
        gagelist={{
          set_one: [
            {
              gage_one: 'vne',
              gage_two: 'vse',
              table_one: 'Dan502_event',
              table_two: 'Dan602_event',
              name: 'East Skew',
            },
            {
              gage_one: 'vnw',
              gage_two: 'vsw',
              table_one: 'Dan501_event',
              table_two: 'Dan601_event',
              name: 'West Skew',
            },
          ],
          y_range: [0, 70],
          title: 'Longitudinal Skew',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{ y_axes_range: [0.0, 6], y_axes_label: 'Long. Skew (in)', longskew:1 }}
      />

      <h2 className='bg-slate-300 text-2xl'>Horizontal</h2>
      <h6 className="bg-slate-300 text-xs">Displayed interval 1 sec.</h6>

      <Graph_danziger_TS
        gagelist={{
          primary: [
            { gage: 'tnw', name: 'TNW', table: 'Dan501_event' },
            { gage: 'tsw', name: 'TSW', table: 'Dan601_event', tare: 0 },
            {
              gage: 'lnw_ultra_in',
              name: 'LNW',
              table: 'Dan601_event',
              tare: 20.2,
              smooth: 10,
            },
            {
              gage: 'lsw_ultra_in',
              name: 'LSW',
              table: 'Dan601_event',
              tare: 15.2,
              smooth: 10,
            },
          ],
          y_range: [0, 70],
          title: 'West Horizontal',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-1.0, 1.0],
          y_axis_label: 'Displacement (in.)',
        }}
      />

      <Graph_danziger_TS
        gagelist={{
          primary: [

            {
              gage: "tne_ultra_in",
              name: "TNE",
              table: "Dan602_event",
              tare: 15.0,
            },
            {
              gage: 'tse_ultra_in',
              name: 'TSE',
              table: 'Dan602_event',
              tare: 17.0,
              smooth: 10,
            },
            {
              gage: 'lne_ultra_in',
              name: 'LNE',
              table: 'Dan602_event',
              tare: 18.0,
              smooth: 10,
            },

            {
              gage: 'lse_ultra_in',
              name: 'LSE',
              table: 'Dan602_event',
              tare: '17.0',
              smooth: 10,
            },

          ],
          y_range: [0, 70],
          title: 'East Horizontal',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-2.0, 2.0],
          y_axis_label: 'Displacement (in.)',
        }}
      />

      <h2 className='bg-slate-300 text-2xl'>Shoe Movement</h2>
      <h6 className="bg-slate-300 text-xs">Displayed interval 1 sec.</h6>
      <Graph_danziger_TS
        gagelist={{
          primary: [
            { gage: 'nw_shoe', name: 'nw_shoe', table: 'Dan501_event' },
            {
              gage: 'sw_shoe',
              name: 'sw_shoe',
              table: 'Dan501_event',
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: 'Shoe Movement',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-0.25, 1.0],
          y_axis_label: 'Displacement (in.)',
        }}
      />
      <Graph_danziger_TS
        gagelist={{
          primary: [
            { gage: 'ne_shoe', name: 'ne_shoe', table: 'Dan502_event' },
            {
              gage: 'se_shoe',
              name: 'se_shoe',
              table: 'Dan502_event',
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: 'Shoe Movement',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-0.25, 1.0],
          y_axis_label: 'Displacement (in.)',
        }}
      />

      <h2 className='bg-slate-300 text-2xl'>Tilts Movement</h2>
      <h6 className="bg-slate-300 text-xs">Displayed interval 1 sec.</h6>
      <Graph_danziger_TS
        gagelist={{
          primary: [
            {
              gage: 'tilt_about_ns',
              name: 'Tilt_NS',
              table: 'Dan501_event',
              smooth: 10,
            },
            {
              gage: 'tilt_about_ew',
              name: 'Tilt_EW',
              table: 'Dan501_event',
              tare: 0,
            },
          ],

          y_range: [0, 70],
          title: 'West End Tilts',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-1.0, 1.0],
          y_axis_label: 'Rotation (in.)',
        }}
      />
      <Graph_danziger_TS
        gagelist={{
          primary: [
            {
              gage: 'tilt_about_ns',
              name: 'Tilt_NS',
              table: 'Dan502_event',
              smooth: 10,
            },
            {
              gage: 'tilt_about_ew',
              name: 'Tilt_EW',
              table: 'Dan502_event',
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: 'East End Tilts',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{ y_axes_range: [-1.0, 1.0], y_axis_label: 'Rotation (deg.)' }}
      />

    <h2 className='bg-slate-300 text-2xl '>Bearing Data</h2>
    <h6 className="bg-slate-300 text-xs">Summary of frequency band data</h6>
      <div className="flex flex-row space-x-5 columns-2">
        <div>
        <BearingDataSimple eventtime={start_time} />
      <BearingData eventtime={start_time} />
      </div>

      
      
      <div>
      <BearingDataSimpleEast eventtime={start_time} />
      <BearingDataEast eventtime={start_time} />
      </div>

    </div> </div>
  )
}

export default PlotScreenDanzEvent
