import { Container } from "postcss"
import React from "react"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import LulingPlots from "./LulingPlots"
// import Spinner from '../components/layout/Spinner'
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

function PlotScreenLuling() {
  const today = new Date()
  const start = new Date(today)
  const { duration } = useParams()
  let days = 14
  console.log(days)
  if (duration != null) {
    days = duration
  }
  start.setDate(start.getDate() - days)
  today.setDate(today.getDate() + 1)
  let start_time = start.toISOString().split("T")[0] + " 00:00:00"
  let end_time = today.toISOString().split("T")[0] + " 00:00:00"
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [startTime, setStartTime] = useState(today.toISOString().split("T")[0] + " 00:00:00")
  const [endTime, setEndTime] = useState(today.toISOString().split("T")[0] + " 00:00:00")
  const [isLoading, setLoading] = useState(true)
  





  // today.toISOString()
  // yesterday.toISOString()
  // console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)


  useEffect(() => {
    setLoading(true)
    const getDates = async () => {
      if (endDate - startDate > 0 && (endDate - startDate) / 86400000 < 15) {
        setStartTime(startDate.toISOString().split("T")[0] + " 00:00:00")
        setEndTime(endDate.toISOString().split("T")[0] + " 00:00:00")
      }
      console.log(`start_time: ${startTime} and end_time: ${endTime}`)
      console.log("deltaTime: ", (endDate - startDate) / 86400000)
      setLoading(false)
    }
    getDates()
  }, [start_time, end_time, startDate, endDate])

  if (false) {
    return (
      <div>Loading or No data available on West

    </div>
    )
  } else {

    return (
    <div>
      <p>Note this only works for a max of 14 days. Longer periods will not run.
        Start Date:{" "}
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
        End Date:{" "}
        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
      </p>
      <p>
        Start Time: {startTime} and End Time: {endTime}
      </p>
      <LulingPlots start_time={startTime} end_time={endTime} />
    </div>
  )
}}

export default PlotScreenLuling
