import React, { useEffect, useState } from "react";
import Plot from 'react-plotly.js';
const REACT_DJANGO = process.env.REACT_APP_DJANGO
const DataChart = () => {
  const [data, setData] = useState([]);
  const [gage, setGage] = useState('');  // Assuming gage is a string
  const [dateRange, setDateRange] = useState({
    from_date: '',  // yyyy-mm-dd format
    to_date: ''     // yyyy-mm-dd format
  });

  // Fetch data from Django server when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          gage: gage,
          dateRange: [dateRange.from_date, dateRange.to_date]
        };
    
        const response = await fetch(REACT_DJANGO +"/api/seismo/gagedata/", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    

    fetchData();
  }, [gage, dateRange]);

  return (
    <div>
      {/* Form for Gage and DateRange */}
      <div>
        <input value={gage} onChange={(e) => setGage(e.target.value)} placeholder="Enter Gage" />
        <input type="date" value={dateRange.from_date} onChange={(e) => setDateRange(prev => ({ ...prev, from_date: e.target.value }))} />
        <input type="date" value={dateRange.to_date} onChange={(e) => setDateRange(prev => ({ ...prev, to_date: e.target.value }))} />
        <button onClick={() => {}}>Fetch Data</button> {/* Trigger useEffect to refetch data */}
      </div>

      {/* Plot Data */}
      {data.length > 0 && (
        <>
        <Plot
          data={[
            {
              x: data.map(datum => new Date(datum.timestamp)),
              y: data.map(datum => datum.tran_ppv),
              type: 'scatter',
              mode: 'markers',
              name: 'tran_ppv',
            },
            {
              x: data.map(datum => new Date(datum.timestamp)),
              y: data.map(datum => datum.long_ppv),
              type: 'scatter',
              mode: 'markers',
              name: 'long_ppv',
            },
            {
              x: data.map(datum => new Date(datum.timestamp)),
              y: data.map(datum => datum.vert_ppv),
              type: 'scatter',
              mode: 'markers',
              name: 'vert_ppv',
            },
            // Continue for other columns...
          ]}
          layout={{width: 720, height: 440, title: 'Time vs PPV',    legend: {
            x: 1, // Adjust the x-coordinate (0 is leftmost, 1 is rightmost)
            y: 0, // Adjust the y-coordinate (0 is bottommost, 1 is topmost)
            xanchor: 'center', // or 'right' or 'left'
            yanchor: 'btoom' // or 'bottom' or 'middle'
        }}}
        />
        <Plot
          data={[
            {
              x: data.map(datum => datum.tran_hz),
              y: data.map(datum => datum.tran_ppv),
              type: 'scatter',
              mode: 'markers',
              name: 'tran_ppv',
            },
            {
              x: data.map(datum => datum.long_hz),
              y: data.map(datum => datum.long_ppv),
              type: 'scatter',
              mode: 'markers',
              name: 'long_ppv',
            },
            {
              x: data.map(datum => datum.vert_hz),
              y: data.map(datum => datum.vert_ppv),
              type: 'scatter',
              mode: 'markers',
              name: 'vert_ppv',
            },
            // Continue for other columns...
          ]}
          layout={{width: 720, height: 440, title: 'Freq. vs PPV',xaxis: {
            type: 'log', 
            autorange: true
          },
          yaxis: {
            type: 'log', 
            autorange: true
          },    legend: {
            x: 1, // Adjust the x-coordinate (0 is leftmost, 1 is rightmost)
            y: 0, // Adjust the y-coordinate (0 is bottommost, 1 is topmost)
            xanchor: 'center', // or 'right' or 'left'
            yanchor: 'bottom' // or 'bottom' or 'middle'
        }}}
        />
                <Plot
          data={[
            {
              x: data.map(datum => new Date(datum.timestamp)),
              y: data.map(datum => datum.leq),
              type: 'scatter',
              mode: 'markers',
              name: 'Leq',
            },
            {
              x: data.map(datum => new Date(datum.timestamp)),
              y: data.map(datum => datum.lmax),
              type: 'scatter',
              mode: 'markers',
              name: 'Lmax',
            },
            {
              x: data.map(datum => new Date(datum.timestamp)),
              y: data.map(datum => datum.lmin),
              type: 'scatter',
              mode: 'markers',
              name: 'Lmin',
            },
            // Continue for other columns...
          ]}
          layout={{width: 720, height: 440, title: 'Time vs Sound Levels',    legend: {
            x: 1, // Adjust the x-coordinate (0 is leftmost, 1 is rightmost)
            y: 0, // Adjust the y-coordinate (0 is bottommost, 1 is topmost)
            xanchor: 'center', // or 'right' or 'left'
            yanchor: 'bottom' // or 'bottom' or 'middle'
        }}}
        /></>
      )}
    </div>
  );
};

export default DataChart;
