import React, { useState, useEffect } from 'react';
import WaveformListDetail from './WaveformListDetail';
import SeismoHeatmap from './SeismoHeatmap';

function ScreenWaveformEvents() {
  const defaultEndDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 10);

  const [dateRange, setDateRange] = useState({
    from_date: defaultStartDate.toISOString().split('T')[0],
    to_date: defaultEndDate.toISOString().split('T')[0],
  });

  const [seismoid, setSeismoid] = useState('UM14474');
  const [waveformData, setWaveformData] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const handleDateUpdate = () => {
    // You can add additional logic here if needed when the date range is updated
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DJANGO}/api/seismo/getwaveformsslm/?seismoid=${seismoid}&start_date=${dateRange.from_date}&end_date=${dateRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setWaveformData(data.waveforms);
        setHeatmapData(transformDataForHeatmap(data.waveforms));
      }
    };

    fetchData();
  }, [dateRange, seismoid]);


  const transformDataForHeatmap = (waveforms) => {
    const heatmapData = [];
    const start = new Date(dateRange.from_date);
    const end = new Date(dateRange.to_date);
  
    // Create entries for all hours of each day in the date range
    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      const day = d.toISOString().split('T')[0];
      for (let hour = 0; hour < 24; hour++) {
        heatmapData.push({ day, hour, value: 0 });
      }
    }
  
    // Aggregate data by day and hour
    waveforms.forEach(waveform => {
      const date = new Date(waveform.timestamp);
      const day = date.toISOString().split('T')[0];
      const hour = date.getHours();
  
      const existingEntry = heatmapData.find(entry => entry.day === day && entry.hour === hour);
      if (existingEntry) {
        existingEntry.value += 1;
      }
    });
  
    return heatmapData;
  };
  
  

  const onHeatmapClick = (day, hour) => {
    // Filter waveform data for the selected day and hour
    const selected = waveformData.filter(waveform => {
      const date = new Date(waveform.timestamp);
      const waveformDay = date.toISOString().split('T')[0];
      const waveformHour = date.getHours();
  
      return waveformDay === day && waveformHour === hour;
    });
  
    setSelectedEvents(selected);
  };
  

  const handleSeismoidChange = (event) => {
    setSeismoid(event.target.value);
  };


  return (
    <div>
      <h1>Seismograph Event List</h1>
      {/* Dropdown for Seismoid Selection */}
      <select value={seismoid} onChange={handleSeismoidChange}>
        <option value="UM14474">UM14474</option>
        <option value="UM12616">UM12616</option>
        <option value="UM10308">UM10308</option>
      </select>
      {/* Datepicker and other UI elements */}
      {/* Datepicker and other UI elements */}
      <SeismoHeatmap data={heatmapData} onHeatmapClick={onHeatmapClick} />
      {/* WaveformList component for displaying the selected events */}
      <WaveformListDetail data={selectedEvents} />
    </div>
  );
}

export default ScreenWaveformEvents;