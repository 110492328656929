import React from 'react'
import Mapbox from '../Mapbox'
// import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const DanzigerMap=() => {

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-90.027748, 30.008149]
        },
        properties: {
          title: 'Mapbox',
          description: 'd003'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-90.028134, 30.008056]
        },
        properties: {
          title: 'Mapbox',
          description: 'd002'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-90.027569, 30.008166]
        },
        properties: {
          title: 'Mapbox',
          description: 'd000'
        }
      }
    ]
  };
  // accessToken =
  // 'pk.eyJ1IjoicmxpbmRlbmJlcmciLCJhIjoiY2t6MXR6ajBkMHk0cjJ1cWpvZmJnZGlsMCJ9.K8bYUnCw3vyPHY60-IKaDA'
  return (
    <div>
      <h2 className='bg-slate-300 text-2xl'>Pier Bridge GPS Monitor</h2>
      <h6 className="bg-slate-300 text-xs">Work in progress to show current readings</h6>

    <Mapbox           location={[-90.027748, 30.008149]}
          markers={geojson} />

    </div>
  )
}

export default DanzigerMap
