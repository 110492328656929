import {useState, useContext} from 'react'

import UserContext from '../../context/user/UserContext'
import AlertContext from '../../context/alert/AlertContext'


function UserLogin() {
    const {loginUser} = useContext(UserContext)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')



    const {setAlert} = useContext(AlertContext)

    const handleEmail = (e) => setUsername(e.target.value)
    const handlePassword = (e) => setPassword(e.target.value)

    const handleSubmit = (e) => {
        e.preventDefault()

        if(username === '') {
            setAlert('Please enter email address', 'error')
        } else {
            loginUser(username,password)


            setUsername('')
            setPassword('')
        }
    }

  return (
    <div className='grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 mb-8 gap-8'>
        <h1 className='text-4xl font-bold ml-8 mb-8'>Login</h1>
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <div className="relative">
                        <input type="text" className="w-full pr-40 bg-gray-200 input input-lg text-black" 
                        placeholder='email'
                        value={username}
                        onChange={handleEmail} />
                        
                        <input type="password" className="w-full pr-40 bg-gray-200 input input-lg text-black" 
                        // type='password'
                        placeholder='password'
                        aria-describedby="password-constraints"
                        value={password}
                        onChange={handlePassword} />
                        <button type='submit' className="absolute top-0 right-0 rounded-l-none w-36 btn btn-lg">Login</button>
                    </div>
                </div>
            </form>
        </div>
        {/* {users.length > 0 && (        
        <div>
            <button onClick={clearUsers} className="btn btn-ghost btn-lg">
                Clear
            </button>
        </div>
        )} */}

    </div>
  )
}

export default UserLogin
