import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const SeismoHeatmap = ({ data, onHeatmapClick }) => {
  const d3Container = useRef(null);


  useEffect(() => {
    if (data && d3Container.current) {
      d3.select(d3Container.current).selectAll("*").remove();

      const margin = { top: 50, right: 0, bottom: 100, left: 30 };
      const width = 450 - margin.left - margin.right;
      const height = 450 - margin.top - margin.bottom;

      const svg = d3.select(d3Container.current)
        .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

      // Extract unique days and hours
      const days = Array.from(new Set(data.map(item => item.day)));
      const hours = Array.from(new Set(data.map(item => item.hour)));

      // Create scales
      const x = d3.scaleBand()
        .range([0, width])
        .domain(days)
        .padding(0.01);
      const y = d3.scaleBand()
        .range([height, 0])
        .domain(hours)
        .padding(0.01);

      // Create a color scale
      const colorScale = d3.scaleLinear()
        .domain([0, 1, 5, 10, 20])
        .range(["lightgreen", "lightyellow", "yellow", "orange", "red"]);

      // Add the squares
      svg.selectAll()
        .data(data, function(d) { return d.day + ':' + d.hour; })
        .enter()
        .append("rect")
          .attr("x", function(d) { return x(d.day); })
          .attr("y", function(d) { return y(d.hour); })
          .attr("width", x.bandwidth())
          .attr("height", y.bandwidth())
          .style("fill", function(d) { return colorScale(d.value); })
          .on("click", function(event, d) {
            onHeatmapClick(d.day, d.hour);
          });

      // Add event count text
      svg.selectAll()
        .data(data, function(d) { return d.day + ':' + d.hour; })
        .enter()
        .append("text")
          .attr("x", function(d) { return x(d.day) + x.bandwidth() / 2; })
          .attr("y", function(d) { return y(d.hour) + y.bandwidth() / 2; })
          .attr("dy", ".35em") // Vertically center text
          .attr("text-anchor", "middle") // Center text horizontally
          .text(function(d) { return d.value; })
          .style("fill", "black")
          .style("font-size", 10);

      // Add the X Axis
      svg.append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
          .attr("transform", "rotate(-45)")
          .style("text-anchor", "end");

      // Add the Y Axis
      svg.append("g")
        .call(d3.axisLeft(y));
    }
  }, [data, onHeatmapClick]);

  return (
    <div>
      <div ref={d3Container} />
    </div>
  );
};

export default SeismoHeatmap;
