import React from 'react'

function About() {
  return (
    <div>
      <h1 className="text-6xl mb-4">WJE Analytics</h1>
      <p className='mb-4 text-2xl font-light'>
        Interactive Data Monitoring and Analysis --
        {/* <a href='http://wje-analytics.com/'>
          {' '}
          WJE Analytics
        </a>{' '} */}
        
        <strong>
          <a href='https://wje-analytics.com'> WJE Analytics</a>
        </strong>
        .
      </p>
      <p className='text-lg text-gray-400'>
        Version <span className='text-gray'>1.0.0</span>
      </p>
      <p className='text-lg text-gray-400'>
        System By:
        <a className='text-gray' href='https://twitter.com/rlindenberg'>
          Richard Lindenberg
        </a>
      </p>
    </div>
  )
}

export default About
