import React from 'react'
import GraphSeismo from '../../components/plots/PlotSeismo'
import GraphSeismoHz from '../../components/plots/PlotSeismoHz'
// import Spinner from '../components/layout/Spinner'
import PlotWaveform from '../../components/plots/PlotWaveform'

function PlotScreenWaveform() {

const today = new Date()
const start = new Date(today)

start.setDate(start.getDate() -5)
today.setDate(today.getDate() + 1)
// today.toISOString()
// yesterday.toISOString()
// console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)
const start_time = start.toISOString().split('T')[0] + ' 00:00:00'
const end_time = today.toISOString().split('T')[0] + ' 00:00:00'

// const start_time = '2022-02-21 00:00:00'
// const end_time = '2022-02-26 00:00:00'

  return (
    <div>
      <h1 className='text-3xl'>NIH</h1>
      <h2 className='bg-slate-300 text-2xl'>Vibrations</h2>
     <PlotWaveform />
    </div>
  )


}

export default PlotScreenWaveform
