import React from "react"
import * as d3 from "d3"
import Instrumention from './westview.PNG'

function GraphicW(data) {
  console.log("graphic inner")
  console.log(data.data)

  const numberFormat = (temp) => {
    console.log("inside tempFormat")
    if (temp) {
      return temp.toFixed(2)
    } else {
      return "NA"
    }
  }

  const getDataItem = (list, sensor, type) => {
    let obj = list.data.status_leap.find(
      (x) => x.location === sensor && x.sensortype === type
    )
    let index = list.data.status_leap.indexOf(obj)

    let sensorValue = numberFormat(list.data.status_leap[index].sensor_value)
    return sensorValue
  }

  const getDataTime = (list, sensor, type) => {
    let obj = list.data.status_leap.find(
      (x) => x.location === sensor && x.sensortype === type
    )
    let index = list.data.status_leap.indexOf(obj)

    //let sensorValue = (list.data.status_leap[index].last_timestamp)
    const timestamp = new Date(list.data.status_leap[index].last_timestamp)
    let sensorValue = `${timestamp.getFullYear()}-${String(timestamp.getMonth() + 1).padStart(2, '0')}-${String(timestamp.getDate()).padStart(2, '0')} ${String(timestamp.getHours()).padStart(2, '0')}:${String(timestamp.getMinutes()).padStart(2, '0')}`
    return sensorValue
  }

  const svgRef = React.useRef(null)

  // Create a tooltip div
  const tooltip = d3
    .select("body")
    .append("div")
    .style("position", "absolute")
    .style("z-index", "10")
    .style("visibility", "hidden")
    .style("background", "white")
    .style("border", "solid 1px #ccc")
    .style("border-radius", "5px")
    .style("padding", "10px")

  const dimensions = {
    width: 1016,
    height: 460,
    margin: { top: 0, right: 30, bottom: 30, left: 60 },
  }

  const { width, height, margin } = dimensions
  const svgWidth = width + margin.left + margin.right
  const svgHeight = height + margin.top + margin.bottom

  React.useEffect(() => {
    const svgEl = d3.select(svgRef.current)
    //let test = getDataItem(data, "B-2_489.00", "temperature")
    const svg = svgEl
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    const createSensorLabel = (sensor, type, x, y) => {
      // Add the text label
      const textLabel = svg
        .append("text")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", ".35em")
        .attr("fill", "red")
        .text(sensor)
        .attr("font-size", "0.7em")

      // Get the width and height of the text label
      const bbox = textLabel.node().getBBox()
      const width = bbox.width
      const height = bbox.height

      // Add an invisible rectangle over the text label
      const tooltipArea = svg
        .append("rect")
        .attr("x", x)
        .attr("y", y - height)
        .attr("width", width)
        .attr("height", height)
        .style("fill", "transparent")

      // Attach the mouse events to the rectangle
      tooltipArea.on("mousemove", function (event) {
        const [x, y] = d3.pointer(event)
        let sensortext = ""
        // Get sensor values
        // if (type === "temperature") {
        //   const temperature = getDataItem(data, sensor, "temperature")
        //   const humidity = getDataItem(data, sensor, "humidity")
        //   const last_time = getDataTime(data, sensor, "humidity")
  
        //   sensortext = `T: ${temperature} H: ${humidity} <br> Last Update: ${last_time}` //
        // } else {
        //   const wetness = getDataItem(data, sensor, "wetness")
        //   const last_time = getDataTime(data, sensor, "wetness")
  
        //   sensortext = `W: ${wetness} <br> Last Update: ${last_time}` //
        // }

        // Update the tooltip content and position
        tooltip
          .html(`Sensor: ${sensor}<br>${sensortext}`)
          .style("top", event.pageY - 10 + "px")
          .style("left", event.pageX + 10 + "px")
          .style("visibility", "visible")
      })


      tooltipArea.on("mouseout", function () {
        tooltip.style("visibility", "hidden")
      })
    }

    svg
      .append("image")
      .attr("xlink:href", Instrumention)
      //   .attr('xlink:href', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0u8Q5eULmw4XN3KMnkgjcCjDj9fUVVvb9QA&usqp=CAU')
      .attr("width", 1016)
      .attr("height", 460)

    // svg


    svg.on("mousemove", function (event) {
      // console.log("x - using invert", d3.pointer(this)[0]);
      // console.log("y- using invert", d3.pointer(this)[1]);
      console.log(d3.pointer(event))
    })

    svg.on("mouseout", function () {
      tooltip.style("visibility", "hidden")
    })

    createSensorLabel("V-3", "temperature", 527, 180)
    createSensorLabel("V-1", "temperature", 423, 311)
    createSensorLabel("V-2", "temperature", 523, 306)
    //   var textout = 'hello'
  }, [svgRef])
  return <svg ref={svgRef} width={svgWidth} height={svgHeight} />
}
export default GraphicW
